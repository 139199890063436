import React, { useEffect, useState } from "react";
import { FaPills } from 'react-icons/fa';
import { FiArrowRight } from 'react-icons/fi';
import { useHistory, useRouteMatch } from 'react-router-dom';
import Header from "../../../../components/Header";
import platform_repository from '../../../../repositories/platform_repository';

// import { FiFilm, FiDownload, FiFileText,  FiMenu } from 'react-icons/fi'


export default function Pilulas() {
    const [pilulas, setPilulas] = useState([])
    const [loading, setLoading] = useState(false);
    const [errorMessage, setError] = useState(null)

    async function getPilulas() {
        setLoading(true)
        const response = await platform_repository.getPilulas();
        setLoading(false)
        if (response.success) {
            setPilulas(response.data);
        } else {
            setError(response.error)
        }

    }

    useEffect(() => {
        getPilulas()
    }, [])

    return (
        <div className="pilulasMain">
            <Header title="Pílulas" />

            <section className="bg-gray-100 p-8 m-4 lg:m-8 rounded-sm shadow-md">
                {!loading ?
                    errorMessage == null ?
                        pilulas.length == 0 ? <label>Nenhuma Pilula até o momento</label>
                            : <PilulasSection pilulas={pilulas} />
                        : <label>{errorMessage}</label>
                    : <label>Carregando...</label>

                }
            </section>
        </div>
    )
}

function PilulasSection({ pilulas }) {
    const match = useRouteMatch();
    const history = useHistory();

    return (
        <div className="grid grid-cols-1 lg:grid-cols-4 gap-8 " >
            {
                pilulas.map(pilula => {
                    return (
                        // <Accordion title={pilula.name} videoID={pilula.videoLink} />
                        <div onClick={() => history.push(match.url + '/description', pilula)} className="transition duration-500 transform hover:-translate-y-1 flex flex-col shadow-md rounded-md bg-transparent text-green-800 border-2 border-green-700 font-bold text-white hover:bg-blue-900 hover:text-white hover:border-opacity-0 p-4 cursor-pointer justify-between" key={pilula.id}>
                            <p>{pilula.nome}</p>
                            <div className="icons" style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>

                                <FaPills />

                                <div><FiArrowRight /></div>
                            </div>
                        </div>


                    )
                })
            }
        </div>
    )
}