import React, { useEffect, useState } from "react";
import { FiDownload } from "react-icons/fi";
import Header from "../../../../components/Header";
import platform_repository from "../../../../repositories/platform_repository";

export default function ExerciciosExtras() {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setError] = useState(null);
  const [exerciciosExtras, setExerciciosExtras] = useState([]);

  async function getExerciciosExtras() {
    setLoading(true);
    const response = await platform_repository.getExerciciosExtras();
    setLoading(false);
    if (response.success) {
      setExerciciosExtras(response.data);
    } else {
      setError(response.error);
    }
  }

  useEffect(() => {
    getExerciciosExtras();
  }, []);

  return (
    <div className="exerciciosExtrasMain">
      <Header title="Exercícios Extras" />

      <section className="bg-gray-100 p-8 m-4 lg:m-8 rounded-sm shadow-md">
        {!loading ? (
          errorMessage == null ? (
            exerciciosExtras.length == 0 ? (
              <label>Nenhum conteúdo até o momento</label>
            ) : (
              <ExerciciosExtrasSection exerciciosExtras={exerciciosExtras} />
            )
          ) : (
            <label>{errorMessage}</label>
          )
        ) : (
          <label>Carregando...</label>
        )}
      </section>
    </div>
  );
}

function ExerciciosExtrasSection({ exerciciosExtras }) {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-4 gap-8 ">
      {exerciciosExtras
        .filter((ex) => !!ex.linkDownload)
        .map((exercicio) => {
          console.log(exercicio);
          return (
            <a
              key={exercicio.id}
              className="transition duration-500 transform hover:-translate-y-1 flex flex-col shadow-md rounded-md bg-blue-900 text-white border-2 border-blue-900 font-bold text-white hover:text-green-500 hover:border-opacity-0 p-4 cursor-pointer "
              href={exercicio.linkDownload ? exercicio.linkDownload : ""}
              target="_blank"
            >
              <h2 className="text-xl mb-4">{exercicio.nome}</h2>

              <div className="flex items-center">
                <FiDownload className="mr-3"></FiDownload>
                <p>Download</p>
              </div>
            </a>
          );
        })}
    </div>
  );
}
