

import fotoTeacher from '../../../assets/images/fotoTeacher2.png'
import { FiFacebook, FiYoutube, FiInstagram } from 'react-icons/fi'
import { FaTelegramPlane, FaWhatsapp } from 'react-icons/fa'


export default function About() {
    return (
        <div className="container w-screen mx-auto">
            <div className="flex flex-wrap ">
                <div className="flex flex-col flex-1 p-8" >
                    <h3 className="text-5xl text-gray-800 uppercase font-semibold" > Professor Marcelo </h3>
                    <p className="text-xl text-gray-400 mt-4">
                        Formado em Geografia (UFSC) e Pedagogia (UNINOVE) com especialização em Gestão Escolar (UCB) e mestrado em Planejamento Urbano e desenvolvimento socioambiental (UDESC) e doutorando em Educação Científica e Tecnológica pela UFSC.
                        Possui mais de 20 anos de experiência na preparação de alunos para vestibulares, ENEM e concursos
                    </p>
                    <div className="flex flex-row flex-wrap justify-center lg:justify-start text-white space-x-4 mt-8 w-full lg:w:4/6" >
                        <a className="p-4 rounded-full shadow-lg bg-gradient-to-r from-blue-600 to-green-600 text-3xl" target="_blank" href="https://www.youtube.com/user/Geografiairada"><FiYoutube className='teacherIcon' /></a>
                        <a className="p-4 rounded-full shadow-lg bg-gradient-to-r from-blue-600 to-green-600 text-3xl" target="_blank" href="https://www.instagram.com/geografiairada/?hl=pt-br"><FiInstagram className='teacherIcon' /></a>
                        <a className="p-4 rounded-full shadow-lg bg-gradient-to-r from-blue-600 to-green-600 text-3xl" target="_blank" href="https://www.facebook.com/Geografiairada"><FiFacebook className='teacherIcon' /></a>
                        <a className="p-4 rounded-full shadow-lg bg-gradient-to-r from-blue-600 to-green-600 text-3xl" target="_blank" href="https://t.me/geografiairada"><FaTelegramPlane className='teacherIcon' /></a>
                        <a className="p-4 rounded-full shadow-lg bg-gradient-to-r from-blue-600 to-green-600 text-3xl" target="_blank" href="https://api.whatsapp.com/send?phone=554799813532&text="><FaWhatsapp className='teacherIcon' /></a>
                    </div>
                </div>
                <div className="flex justify-center w-full lg:w-2/6" >
                    <img src={fotoTeacher} width="340px" />
                </div>
            </div>
        </div>
    )
}