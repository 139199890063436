import React, { useEffect, useState } from "react";
import { FiBookOpen, FiEdit2 } from "react-icons/fi";
import { useMediaQuery } from "react-responsive";
import Header from "../../../../components/Header";
import platform_repository from "../../../../repositories/platform_repository";

export default function Simulados() {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setError] = useState(null);
  const [simulados, setSimulados] = useState([]);

  async function getSimulados() {
    setLoading(true);
    const response = await platform_repository.getSimulados();
    setLoading(false);
    if (response.success) {
      setSimulados(response.data);
    } else {
      setError(response.error);
    }
  }

  useEffect(() => {
    getSimulados();
  }, []);

  return (
    <div className="simuladosMain">
      <Header title="Simulados" />

      <section className="bg-gray-100 p-8 m-4 lg:m-8 rounded-sm shadow-md">
        {!loading ? (
          errorMessage == null ? (
            simulados.length == 0 ? (
              <label>Nenhum Simulado até o momento</label>
            ) : (
              <SimuladosSection simulados={simulados} />
            )
          ) : (
            <label>{errorMessage}</label>
          )
        ) : (
          <label>Carregando...</label>
        )}
      </section>
    </div>
  );
}

function SimuladosSection({ simulados }) {
  console.log(simulados);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  return simulados.map((simulado) => {
    return (
      <div
        className="bg-gray-200 flex lg:flex-row flex-col flex-wrap p-8 mb-4 rounded-md"
        key={simulado.id}
      >
        <div className="flex flex-col flex-1">
          <p className="text-gray-700 text-xl font-semibold mb-6">
            {simulado.nome}
          </p>
          <div className="flex flex-col lg:mx-40 mb-4 lg:mb-0">
            <a
              href={
                simulado.linkDownloadSimulado
                  ? simulado.linkDownloadSimulado
                  : ""
              }
              className="flex justify-center items-center transition bg-gradient-to-r mr-2 lg:mr-5 from-blue-600 to-green-600 text-white font-bold px-8 py-2 rounded-md hover:from-blue-500 hover:to-green-500 border-b-4 border-green-800 hover:border-opacity-0 transform hover:translate-y-1 mb-4"
              target="_blank"
            >
              <FiEdit2 className="mr-2" />
              Simulado
            </a>
            <a
              href={
                simulado.linkDownloadGabarito
                  ? simulado.linkDownloadGabarito
                  : ""
              }
              className="flex justify-center items-center transition bg-gradient-to-r mr-2 lg:mr-5 from-blue-600 to-green-600 text-white font-bold px-8 py-2 rounded-md hover:from-blue-500 hover:to-green-500 border-b-4 border-green-800 hover:border-opacity-0 transform hover:translate-y-1 "
              target="_blank"
            >
              <FiBookOpen className="mr-2" />
              Gabarito
            </a>
          </div>
        </div>
        {/* <Youtube className="simuladoVideo" videoId={simulado.videoLink} opts={{ width: 380, height: 180 }}></Youtube> */}
        <div className="flex flex-1 justify-center mt-2">
          <div className="relative w-full h-72">
            <iframe
              title={simulado.nome}
              src={`https://iframe.mediadelivery.net/embed/140249/${simulado.linkVideo}?autoplay=false&loop=false&muted=false&preload=true`}
              loading="lazy"
              style={{
                border: "none",
                position: "absolute",
                top: 0,
                height: "100%",
                width: "100%",
              }}
              allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;"
              allowfullscreen="true"
            ></iframe>
          </div>
        </div>
      </div>
    );
  });
}
