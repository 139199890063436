import React, { useEffect, useState } from "react";
import { FaFileDownload } from "react-icons/fa";
import Header from "../../../../components/Header";
import platform_repository from "../../../../repositories/platform_repository";
import { BASE_URL } from "../../../../utils/constants";

export default function PlanoDeEstudos() {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setError] = useState(null);
  const [schedules, setSchedules] = useState([]);

  // const local = getUserData()
  // const classes = local.plan.classes;

  async function getSchedules(classid) {
    setLoading(true);
    const response = await platform_repository.getSchedules(classid);
    setLoading(false);
    if (response.success) {
      setSchedules(response.data);
    } else {
      setError(response.error);
    }
  }

  useEffect(() => {
    getSchedules(0);
  }, []);

  return (
    <div className="exerciciosExtrasMain">
      <Header
        title="Plano de Estudos"
        showDropdown
        onDropdownChange={getSchedules}
      />

      <section className="bg-gray-100 p-8 m-4 lg:m-8 rounded-sm shadow-md">
        {!loading ? (
          errorMessage == null ? (
            schedules.length == 0 ? (
              <label>Nenhum Plano de Estudos até o momento</label>
            ) : (
              <PlanoDeEstudosSection planoDeEstudos={schedules} />
            )
          ) : (
            <label>{errorMessage}</label>
          )
        ) : (
          <label>Carregando...</label>
        )}
      </section>
    </div>
  );
}

function PlanoDeEstudosSection({ planoDeEstudos }) {
  console.log(planoDeEstudos);
  return (
    <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 ">
      {planoDeEstudos.map((schedule) => {
        return (
          <a
            key={schedule.id}
            className="transition duration-500 transform hover:-translate-y-1 flex flex-col shadow-md rounded-md bg-transparent text-green-800 border-2 border-green-700 font-bold text-white hover:bg-green-800 hover:text-white hover:border-opacity-0 p-4 cursor-pointer text-center items-center"
            href={schedule.linkDownloadMaterial}
            target="_blank"
          >
            <h2 className="text-2xl mb-4">{schedule.nome}</h2>
            <div className="flex ">
              <FaFileDownload className="text-xl mr-2"></FaFileDownload>
              <span>Download</span>
            </div>
          </a>
        );
      })}
    </div>
  );
}
