import React, { useEffect, useState } from 'react';
import { FaMusic } from 'react-icons/fa';
import { FiArrowRight } from 'react-icons/fi';
import { useHistory, useRouteMatch } from 'react-router-dom';
import Header from '../../../components/Header';
import platform_repository from '../../../repositories/platform_repository';

export default function Parodias() {
    const [loading, setLoading] = useState(false);
    const [errorMessage, setError] = useState(null)
    const [parodias, setParodias] = useState([])

    async function getParodias() {
        setLoading(true)
        const response = await platform_repository.getParodias();
        setLoading(false)
        if (response.success) {
            setParodias(response.data);
        } else {
            setError(response.error)
        }
    }

    useEffect(() => {
        getParodias()
    }, [])

    return (
        <div className="parodiasMain">
            <Header title="Paródias" />

            <section className="bg-gray-100 p-8 m-4 lg:m-8 rounded-sm shadow-md">
                {
                    !loading ?
                        errorMessage == null ?
                            parodias.length == 0 ? <label>Nenhuma Paródias até o momento</label>
                                : <ParodiasSection parodias={parodias} />
                            : <label>{errorMessage}</label>
                        : <label>Carregando...</label>
                }


            </section>
        </div>
    )
}

function ParodiasSection({ parodias }) {
    const match = useRouteMatch();
    const history = useHistory();
    return (
        <div className="grid grid-cols-1 lg:grid-cols-4 gap-8 ">
            {
                parodias.map(parodia => {
                    return (
                        <div onClick={() => history.push(match.url + '/description', parodia)} className="transition duration-500 transform hover:-translate-y-1 flex flex-col shadow-md rounded-md bg-transparent text-green-800 border-2 border-green-700 font-bold text-white hover:bg-blue-900 hover:text-white hover:border-opacity-0 p-4 cursor-pointer justify-between" key={parodia.id}>
                            <p>{parodia.nome}</p>
                            <div className="icons" style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                                <FaMusic />
                                <div><FiArrowRight /></div>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}