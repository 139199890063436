import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { AuthProvider } from "./context/AuthProvider";
import ForgetPasswordPage from "./pages/ForgetPassword";
import Front from "./pages/Front";
import LoginPage from "./pages/Login";
import Obrigado from "./pages/Obrigado";
import Platform from "./pages/Platform";
import RedefinePassword from "./pages/RedefinePassword";
import SignUpPage from "./pages/SignUp";
import { isAuthenticated } from "./services/auth";

const PlatformRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => <Component {...props} />} />
);
// const PlatformRoute = ({ component: Component, ...rest }) => (
//   <Route {...rest} render={(props) => (isAuthenticated() ? <Component {...props} /> : <Redirect to={"/login"} />)} />
// );

const LoginRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (!isAuthenticated() ? <Component {...props} /> : <Redirect to={"/plataforma/aulas"} />)}
  />
);

export default function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <Switch>
          <Route path="/" exact component={Front}></Route>
          <LoginRoute path="/login" component={LoginPage} />
          <LoginRoute path="/cadastro" component={SignUpPage} />
          {/* <Route path="/login" component={LoginPage} /> */}
          <PlatformRoute path="/plataforma" component={Platform} />
          {/* <Route path="/plataforma" component={Platform} /> */}
          {/* <Route path="/profile"  component={Plataforma}></Route> */}
          {/* <Route path="/admin/login" component={LoginAdmin}></Route>
                <Route path="/admin/cadastro"  component={Cadastros}></Route>
                <Route path="/admin/update"  component={Alterar}></Route> */}
          <Route path="/updatepassword" component={RedefinePassword}></Route>
          {/* <Route path="/emailsent"  component={EmailSent}></Route> */}
          <Route path="/recuperar-senha" component={ForgetPasswordPage}></Route>
          <Route path="/agradecimento" component={Obrigado}></Route>
        </Switch>
      </BrowserRouter>
    </AuthProvider>
  );
}
