import React from "react";
import { useHistory } from "react-router-dom";
import logo from "../../assets/images/logoSemFundo.png";

export default function Obrigado() {
  const history = useHistory();
  return (
    <div className="min-h-screen flex justify-center bg-gradient-to-r from-blue-800 to-green-500 ">
      <div className="max-w-lg w-full">
        <div className="flex flex-col items-center justify-center bg-white h-screen p-8 lg:shadow-md">
          <div>
            <img
              className="mx-auto h-24 w-auto"
              src={logo}
              alt="gepgrafia Irada"
            />
            <h2 className="mt-6 text-center text-3xl font-bold text-gray-900">
              Seja Bem-Vindo!
            </h2>
            <p className="mt-2 text-center text-md text-gray-600">
              <p>Agora você já é um Aluno Irado</p>
            </p>
            <p className="mt-2 text-center text-sm text-gray-500">
              Clique abaixo para acessar a plataforma e ter acesso a todos os
              conteudos disponiveis!
            </p>

            <button
              onClick={() => history.push("/login")}
              className="mt-5 w-full transition px-2 md:px-7 py-2 text-sm md:text-base bg-gradient-to-r from-blue-600 md:from-blue-700 to-green-500 rounded-md text-white font-bold cursor-pointer border-b-4 border-blue-900 transform hover:translate-y-1 hover:border-opacity-0 hover:from-green-400 hover:to-green-400 sm:hover:from-blue-600 sm:hover:to-green-300 text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Acesse a Plataforma{" "}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
