import React from 'react';
import { useMediaQuery } from 'react-responsive';
import Youtube from 'react-youtube';
import Header from '../../../../components/Header';

export default function PilulaPage(props) {
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const pilula = props.location.state;

    function videoType(link) {
        if (link.includes("https://www.youtube.com/watch?v="))
            return (
                <Youtube videoId={link.replace("https://www.youtube.com/watch?v=", "")} className={isTabletOrMobile ? "w-full" : ""} ></Youtube>
            );
        return (
            <iframe src={link} className="w-full" style={{ height: isTabletOrMobile ? "280px" : "360px" }} frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
        )
    }

    return (
        <div className="conteudoPage">
            <Header title="Pilulas Iradas" showBackButton />

            <section>
                <div className="p-4 lg:p-8" >
                    <div className="flex-1 mb-4 lg:mr-4 flex justify-center" style={{ backgroundColor: "#000" }} >
                        {videoType(pilula.linkVideo)}
                    </div>
                    <div className="flex flex-col bg-gray-100 rounded-md shadow-sm p-8 ">
                        <h2 className="text-3xl text-gray-900 font-bold" >{pilula.nome}</h2>
                        <p>Prof. Marcelo</p>

                    </div>
                </div>


            </section>
        </div>
    )
}