import dep1 from "../../../assets/images/depoimentos/1.png";
import dep2 from "../../../assets/images/depoimentos/2.png";
import dep3 from "../../../assets/images/depoimentos/3.png";
import dep4 from "../../../assets/images/depoimentos/4.png";
import dep5 from "../../../assets/images/depoimentos/5.png";
import dep6 from "../../../assets/images/depoimentos/6.png";
import dep7 from "../../../assets/images/depoimentos/7.png";
import dep8 from "../../../assets/images/depoimentos/8.png";
import dep9 from "../../../assets/images/depoimentos/9.png";
// Import Swiper React components
// import { Swiper, SwiperSlide } from 'swiper/react';
import Slider from "react-slick";
// Import Swiper styles
// import 'swiper/swiper.scss';
// import Carousel from 'react-elastic-carousel';
// import '@brainhubeu/react-carousel/lib/style.css';
import Swiper from "react-id-swiper";
import { useMediaQuery } from "react-responsive";
import { useEffect } from "react";

export default function Depositions() {
  const isMobile = useMediaQuery({ maxWidth: "557" });
  let settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    slidesToShow: window.innerWidth < 557 ? 1 : 3,
    slidesToScroll: 1,
    autoplaySpeed: 3000,
  };
  return (
    <div className="container mx-auto w-screen mb-0">
      <div
        className="flex flex-col w-full px-4 items-center space-y-4"
        style={{ height: "52em" }}
      >
        <h2 className="text-3xl text-gray-700 uppercase font-bold mb-12">
          Se ligue nos Resultados de quem já foi um Aluno Irado...
        </h2>
        <div className="grid grid-cols-1 grid-rows-1 lg:grid-cols-1 lg:grid-rows-1 justify-center gap-8">
          <Slider {...settings}>
            <DepositionCard
              name="Pamella Francyelle Dias"
              description="... Quero agradecer em especial ao GEOGRAFIA IRADA que foi precursor das minhas aprovações, melhor plataforma de estudo. Obrigada meu DEUS, você nunca falha"
              school="MEDICINA - UNIPAM"
              image={dep1}
            />
            <DepositionCard
              name="Larissa Senff"
              description="O Geografia Irada foi fundamental na minha conquista. Com uma didática excepcional e um professor extraordinário eu consegui evoluir muito na disciplina. Só tenho a gradecer, pois foi fundamental na minha jornada até a aprovação em medicina."
              school="MEDICINA - UNICAR"
              image={dep2}
            />
            <DepositionCard
              name="Gustavo Dalago"
              description="Quando eu comecei o ensino médio eu não gostava de geografia, mas graças ao Geografia Irada e a forma incrível e dinâmica do professor Marcelo de ensinar, eu consegui GABARITAR a prova de Geografia ."
              school="ENGENHARIA CIVIL - FURB E FURG"
              image={dep4}
            />
            <DepositionCard
              name="Maria Eduarda Frazzon "
              description="Certos professores facilitam o caminho de chegado para nossos sonhos. Tive o prazer de acompanhar suas aulas no terceirão e durante todo seu curso online. O Geografia Irada foi, sem dúvidas, um grande diferencial na minha conquista para tão sonhada vaga na medicina, Obrigado Professor!”"
              school="MEDICINA - UNISUL"
              image={dep8}
            />
            <DepositionCard
              name="Samantha Rosa"
              description="Vim aqui agradecer por essas aulas maravilhosas que me renderam a vaga em medicina 2017 na UEPG. Continue sempre produzindo esse trabalho incrível. Obrigada!!!"
              school="MEDICINA - UEPG"
              image={dep6}
            />
            <DepositionCard
              name="Maria Gabriela Oliveira"
              description="Estudar durante o terceirão com o Geografia Irada fez com que além de aprender o conteúdo, eu gostasse de resolver questões. Só tenho a agradecer, pois toda assistência dada foi fundamental para garantir minha vaga em medicina!! Obrigada professor!!"
              school="MEDICINA - PUC-RS E UCS"
              image={dep5}
            />
            <DepositionCard
              name="Gustavo Cemim"
              description="Professor passei na ULBRA e PUC em medicina estudando apenas online com o senhor. Passei também em direito na UFRGS.Tinha dificuldade em geografia e após estudar com o Geografia Irada evolui muito e praticamente gabarito todas as questões. Obrigado!"
              school="MEDICINA - PUC-RS E ULBRA"
              image={dep3}
            />
          </Slider>
        </div>
      </div>
    </div>
  );
}

function DepositionCard({ name, description, school, image }) {
  return (
    <div
      className="rounded-md shadow-md flex flex-col flex-1 items-center p-8 text-center space-y-2 mx-4 my-2"
      style={{ width: "400px" }}
      style={{ minHeight: "600px" }}
    >
      <img className="rounded-full shadow-md" src={image} />
      <h2 className="text-2xl text-gray-700 font-bold"> {name} </h2>
      <h3 className="text-xl text-gray-600"> {school} </h3>
      <blockquote className="text-lg text-gray-400"> {description} </blockquote>
    </div>
  );
}
