import React, { useEffect, useState } from 'react';
import { FiArrowRight, FiGlobe } from 'react-icons/fi';
import { useHistory, useRouteMatch } from 'react-router-dom';
import Header from '../../../../components/Header';
import platform_repository from '../../../../repositories/platform_repository';


export default function NaReal() {
    const [naReal, setnaReal] = useState([]);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setError] = useState(null)

    async function getNaReals() {
        setLoading(true)
        const response = await platform_repository.getNaReals();
        setLoading(false)
        if (response.success) {
            setnaReal(response.data);
        } else {
            setError(response.error)
        }

    }

    useEffect(() => {
        getNaReals()
    }, [])

    return (
        <div className="naRealMain">
            <Header title="Na Real" />

            <section className="bg-gray-100 p-8 m-4 lg:m-8 rounded-sm shadow-md">
                {!loading ?
                    errorMessage == null ?
                        naReal.length == 0 ? <label>Nenhum Na Real até o momento</label>
                            : <NaRealSection naReals={naReal} />
                        : <label>{errorMessage}</label>
                    : <label>Carregando...</label>

                }
            </section>
        </div>
    )
}

function NaRealSection({ naReals }) {
    const match = useRouteMatch();
    const history = useHistory();

    return (
        <div className="grid grid-cols-1 lg:grid-cols-4 gap-8 " >
            {naReals.map(nareal => {
                return (
                    <div onClick={() => history.push(match.url + '/description', nareal)} className="transition duration-500 transform hover:-translate-y-1 flex flex-col shadow-md rounded-md bg-transparent text-green-800 border-2 border-green-700 font-bold text-white hover:bg-blue-900 hover:text-white hover:border-opacity-0 p-4 cursor-pointer justify-between" key={nareal.id}>
                        <p>{nareal.nome}</p>
                        <div className="icons" style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                            <div>
                                <FiGlobe />
                            </div>
                            <div><FiArrowRight /></div>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}