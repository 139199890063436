import React, { useRef, useState } from "react";
import { FiDownload } from "react-icons/fi";
import Youtube from "react-youtube";

import "./Accordion.css";

function Accordion(props) {
  const [setActive, setActiveState] = useState("");
  const [setHeight, setHeightState] = useState("0px");
  const [setRotate, setRotateState] = useState("accordion__icon");

  const content = useRef(null);

  function toggleAccordion() {
    setActiveState(setActive === "" ? "activeAccordion" : "");
    setHeightState(
      setActive === "activeAccordion"
        ? "0px"
        : `${content.current.scrollHeight}px`
    );
    setRotateState(
      setActive === "activeAccordion"
        ? "accordion__icon"
        : "accordion__icon rotate"
    );
  }

  if (props.isVideoExercicio) {
    return (
      <div className="flex flex-col">
        <button className={`accordion ${setActive}`} onClick={toggleAccordion}>
          <p className="accordion__title">{props.title}</p>
          <svg
            className={`${setRotate}`}
            width={10}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 320 512"
            color="#fff"
          >
            <path
              fill={"#fff"}
              d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"
            />
          </svg>
        </button>
        <div
          ref={content}
          style={{ maxHeight: `${setHeight}` }}
          className="accordion__content"
        >
          <div className="accordion__text">
            <Youtube
              videoId={props.videoID.replace(
                "https://www.youtube.com/watch?v=",
                ""
              )}
              className="youtubeAccordion"
            ></Youtube>
            <a
              target="_blank"
              className="flex items-center gap-2"
              href={props.fileUrl}
            >
              {" "}
              <FiDownload /> Download Exercício{" "}
            </a>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col">
      <button
        className={`flex flex-row justify-between items-center px-4 py-2 ${setActive} bg-green-900  rounded-t-md `}
        onClick={toggleAccordion}
      >
        <p className="accordion__title">{props.title}</p>
        <svg
          className={`${setRotate}`}
          width={10}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 320 512"
          color="#fff"
        >
          <path
            fill={"#fff"}
            d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"
          />
        </svg>
      </button>
      <div
        ref={content}
        style={{ maxHeight: `${setHeight}` }}
        className="accordion__content bg-gray-100"
      >
        <div className="accordion__text">
          {" "}
          <Youtube
            videoId={props.videoID.replace(
              "https://www.youtube.com/watch?v=",
              ""
            )}
          ></Youtube>
        </div>
      </div>
    </div>
  );
}

export default Accordion;
