import { BiLogOut } from "react-icons/bi";
import { useHistory } from "react-router-dom";
import checked from "../../../assets/images/checked.png";
import { useAuth } from "../../../context/AuthProvider";

export const BuyPlanPage = () => {
  const { signout, user } = useAuth();
  const history = useHistory();

  const logOut = () => {
    signout();
    history.push("/");
  };

  return (
    <div className="flex flex-col m-6 w-full lg:mx-24 p-4 bg-white rounded-lg  justify-center items-center">
      <h1 className="uppercase text-2xl text-center text-green-600 font-bold">
        Selecione um dos Planos abaixo
      </h1>
      <h2 className="uppercase text-xl text-center text-green-500 font-bold">
        E tenha acesso total a plataforma!
      </h2>
      <div className="mt-4 grid grid-rows-3 grid-cols-1 md:grid-rows-1 md:grid-cols-3 lg:gap-8 items-center">
        <PricingCard
          title="Plano Trimestral"
          monthlyPrice="19,99"
          discountPrice="23"
          url={`https://pay.hotmart.com/F79683093D?email=${user.email}`}
          // url={`/`}
          contents={[
            "Revisão e Aulas Intensivo",
            "Conteúdos e Exercícios Extras",
            "3 Meses de Acesso",
            "Parcelamento em até 3 vezes",
          ]}
        />
        <PricingCard
          title="Plano Anual"
          monthlyPrice="13,90"
          discountPrice="18"
          url={`https://pay.hotmart.com/A79683620H?email=${user.email}`}
          // url={`/`}
          recommended
          contents={[
            "Revisão e Aulas Intensivo",
            "Conteúdos e Exercícios Extras",
            "12 Meses de Acesso",
            "Parcelamento em até 12 vezes",
          ]}
        />
        <PricingCard
          title="Plano Semestral"
          monthlyPrice="16,50"
          discountPrice="19"
          url={`https://pay.hotmart.com/R79683484P?email=${user.email}`}
          // url={`/`}
          contents={[
            "Revisão e Aulas Intensivo",
            "Conteúdos e Exercícios Extras",
            "6 Meses de Acesso",
            "Parcelamento em até 6 vezes",
          ]}
        />
      </div>
      <div className="md:mt-4">
        <button
          onClick={logOut}
          className="text-red-600 text-lg flex items-center justify-center"
        >
          <BiLogOut className="mr-1" /> Sair
        </button>
      </div>
    </div>
  );
};

export function PricingCard({
  title,
  monthlyPrice,
  contents,
  recommended,
  url,
  discountPrice,
}) {
  function onBuyClick() {
    window.open(url);
  }

  return (
    <div className="p-1 object-contain shadow-md bg-white rounded-md bg-gradient-to-tr from-blue-600 to-green-600">
      {recommended && (
        <div className="px-12 py-4 text-white font-bold text-center rounded-lg">
          RECOMENDADO
        </div>
      )}
      <div className="p-8 bg-white rounded-sm text-center">
        <h2 className="text-xl text-gray-700 uppercase font-bold mb-2">
          {" "}
          {title}{" "}
        </h2>
        <div className="w-full flex flex-wrap text-center justify-center">
          <p className="font-semibold text-gray-700 uppercase">de </p>
          <p className="line-through mx-2 font-semibold text-gray-700 uppercase">
            {" "}
            R$ {discountPrice}/mês
          </p>
          <p className="font-semibold text-gray-700 uppercase">por apenas</p>
        </div>
        <div className="w-full flex flex-wrap text-center items-end justify-center mb-4">
          <p className="text-gray-600  text-xl">R$ </p>
          <h2 className="text-gray-700 text-3xl font-bold"> {monthlyPrice} </h2>
          <p className="text-gray-600  text-xl">/mês</p>
        </div>
        <ul className="px-2 lg:px-8 space-y-3">
          {contents.map((content) => (
            <li className="flex space-x-2">
              <img
                src={checked}
                width="24px"
                height="24px"
                className="object-contain"
              />
              <p className="text-gray-500 text-base text-left"> {content} </p>
            </li>
          ))}
        </ul>
        <button
          onClick={onBuyClick}
          className="mt-8 transition bg-gradient-to-r from-blue-400 to-green-600 text-white font-bold px-8 py-2 rounded-md hover:from-blue-300 hover:to-green-500 border-b-4 border-green-800 hover:border-opacity-0 transform hover:translate-y-1"
        >
          Assinar Agora
        </button>
      </div>
    </div>
  );
}
