import {
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  createUserFreePlan,
  getUserPlans,
} from "../repositories/userRepository";
import { auth } from "../services/firebase";

export const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);

  const getUserCurrentPlan = useCallback((plans) => {
    let freePlan = plans.find((plan) => plan.type === "free");
    let notExpiredPlans = plans
      .filter(
        (plan) => plan.expirationDate > Date.now() && plan.type !== "free"
      )
      .sort((a, b) =>
        a.expirationDate > b.expirationDate
          ? 1
          : b.expirationDate > a.expirationDate
          ? -1
          : 0
      );

    if (notExpiredPlans.length > 0) return notExpiredPlans[0];
    return freePlan;
  }, []);

  const signin = useCallback(
    async (email, password) => {
      const response = await signInWithEmailAndPassword(auth, email, password);
      const userPlans = await getUserPlans(response.user.uid);
      setUser({ ...response.user, userPlan: getUserCurrentPlan(userPlans) });
      return response.user;
    },
    [getUserCurrentPlan]
  );

  const signup = useCallback(async (email, password) => {
    const response = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );
    const userPlan = await createUserFreePlan(response.user.uid);
    setUser({ ...response.user, userPlan });
    return response.user;
  }, []);

  const signout = useCallback(() => {
    signOut(auth);
    setUser(false);
    return;
  }, []);

  const sendResetPasswordEmail = useCallback(async (email) => {
    await sendPasswordResetEmail(auth, email);
    return true;
  }, []);

  const confirmPassReset = useCallback(async (code, password) => {
    await confirmPasswordReset(auth, code, password);
    return true;
  }, []);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setLoading(true);
        const userPlans = await getUserPlans(user.uid);
        setUser({ ...user, userPlan: getUserCurrentPlan(userPlans) });
        setLoading(false);
      } else {
        setUser(false);
      }
    });
    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [getUserCurrentPlan]);

  return (
    <AuthContext.Provider
      value={{
        user,
        signin,
        signout,
        signup,
        sendResetPasswordEmail,
        confirmPassReset,
        loading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
