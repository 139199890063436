// import api from '../services/api'
import loadCSVasJSON from "../services/csv_loader";
import { REPOSITORY_RESPONSE } from "../utils/constants";

function substituirColuna(
  entradaASubstituirColuna,
  nomeColunaASubstituir,
  dadosNovos,
  colunaReferenciaDadosNovos
) {
  entradaASubstituirColuna.forEach((registro) => {
    registro[nomeColunaASubstituir] = dadosNovos.find((novoDado) => {
      // console.log(novoDado)
      // console.log(`novoDado ${novoDado[colunaReferenciaDadosNovos]}\nregistro ${registro[nomeColunaASubstituir]}\ne igual ${novoDado[colunaReferenciaDadosNovos] == registro[nomeColunaASubstituir]}`)
      return (
        novoDado[colunaReferenciaDadosNovos] == registro[nomeColunaASubstituir]
      );
    });
  });
}

// function joinRegistros(relacoes, data) {
//     data.esqRegs.forEach(reg => reg[data.newColNameDir] = [])
//     data.dirRegs.forEach(reg => reg[data.newColNameEsq] = [])
//     relacoes.forEach((relacao) => {
//         const registroEsquerdo = data.esqRegs.find(reg => reg[data.refColEsq] == relacao[data.esqCol])
//         const registroDireito = data.dirRegs.find(reg => reg[data.refColDir] == relacao[data.dirCol])
//         // console.log(`esquerdo: ${registroEsquerdo}\ndireito: ${registroDireito}`)
//         if (registroEsquerdo && registroDireito) {
//             registroEsquerdo[data.newColNameDir].push(registroDireito)
//             registroDireito[data.newColNameEsq].push(registroEsquerdo)
//         }
//     })
// }

export default class PlatformRepository {
  static async getClasses() {
    try {
      const response = await loadCSVasJSON("Banco de Dados - Turmas.csv");
      return {
        ...REPOSITORY_RESPONSE,
        success: true,
        data: response,
      };
    } catch (error) {
      console.log(error);

      return {
        ...REPOSITORY_RESPONSE,
        success: false,
        error,
      };
    }
  }
  static async getContents(turma, incluirMaterias) {
    try {
      // const response = await api.get(`/conteudos?_limit=-1&_sort=subject:ASC,id:ASC&turmas.id=${id}`)
      var responseConteudos = await loadCSVasJSON(
        "Banco de Dados - Conteúdos.csv"
      );
      const responseParodias = await loadCSVasJSON(
        "Banco de Dados - Paródias.csv"
      );
      const responseConteudoParodias = await loadCSVasJSON(
        "Banco de Dados - Conteúdo Paródia.csv"
      );
      const responseMaterias = await loadCSVasJSON(
        "Banco de Dados - Matérias.csv"
      );
      const responseExercicioVideo = await loadCSVasJSON(
        "Banco de Dados - Exercício em Vídeo.csv"
      );
      const responseTurmas = await loadCSVasJSON("Banco de Dados - Turmas.csv");
      const responseConteudoTurma = await loadCSVasJSON(
        "Banco de Dados - Conteúdo Turma.csv"
      );
      const nomeTurma = responseTurmas.find((t) => t.id == turma).nome;

      substituirColuna(responseConteudos, "materia", responseMaterias, "nome");
      substituirColuna(responseParodias, "materia", responseMaterias, "nome");
      substituirColuna(
        responseConteudos,
        "exercicioVideo",
        responseExercicioVideo,
        "nome"
      );

      // Join conteudo parodia
      responseConteudos.forEach((reg) => (reg["parodias"] = []));
      responseConteudoParodias.forEach((relacao) => {
        const registroEsquerdo = responseConteudos.find(
          (reg) =>
            reg["nome"] == relacao["nomeConteudo"] &&
            reg["versao"] &&
            relacao["versaoConteudo"]
        );
        const registroDireito = responseParodias.find(
          (reg) => reg["nome"] == relacao["nomeParodia"]
        );
        // console.log(`esquerdo: ${registroEsquerdo}\ndireito: ${registroDireito}`)
        if (registroEsquerdo && registroDireito)
          registroEsquerdo["parodias"].push(registroDireito);
      });

      // Join conteudo turma
      responseConteudos.forEach((reg) => (reg["turmas"] = []));
      responseConteudoTurma.forEach((relacao) => {
        const registroEsquerdo = responseConteudos.find(
          (reg) =>
            reg["nome"] == relacao["nomeConteudo"] &&
            reg["versao"] &&
            relacao["versaoConteudo"]
        );
        const registroDireito = responseTurmas.find(
          (reg) => reg["nome"] == relacao["nomeTurma"]
        );
        // console.log(`esquerdo: ${registroEsquerdo}\ndireito: ${registroDireito}`)
        if (registroEsquerdo && registroDireito)
          registroEsquerdo["turmas"].push(registroDireito);
      });

      // joinRegistros(responseConteudoParodias, {
      //     "esqCol": "nomeConteúdo",
      //     "dirCol": "nomeParódia",
      //     "esqRegs": responseConteudos,
      //     "dirRegs": responseParodias,
      //     "refColEsq": "nome",
      //     "refColDir": "nome",
      //     "newColNameEsq": "conteudos",
      //     "newColNameDir": "parodias"
      // })

      return {
        ...REPOSITORY_RESPONSE,
        success: true,
        data: incluirMaterias
          ? { materias: responseMaterias, conteudos: responseConteudos }
          : responseConteudos,
      };
    } catch (error) {
      console.log(error);

      return {
        ...REPOSITORY_RESPONSE,
        success: false,
        error,
      };
    }
  }
  static async getSchedules() {
    try {
      // const response = await api.get(`/plano-de-estudos?turma.id=${id}`)
      const response = await loadCSVasJSON(
        "Banco de Dados - Plano de Estudos.csv"
      );
      // const response = [];

      return {
        ...REPOSITORY_RESPONSE,
        success: true,
        data: response,
      };
    } catch (error) {
      console.log(error);

      return {
        ...REPOSITORY_RESPONSE,
        success: false,
        error,
      };
    }
  }
  static async getApostilas() {
    try {
      const response = await loadCSVasJSON("Banco de Dados - Apostilas.csv");

      return {
        ...REPOSITORY_RESPONSE,
        success: true,
        data: response,
      };
    } catch (error) {
      console.log(error);

      return {
        ...REPOSITORY_RESPONSE,
        success: false,
        error,
      };
    }
  }
  static async getExerciciosExtras() {
    try {
      // const response = await api.get(`/exercicios-extras`)
      const response = await loadCSVasJSON(
        "Banco de Dados - Exercício Extra.csv"
      );

      return {
        ...REPOSITORY_RESPONSE,
        success: true,
        data: response,
      };
    } catch (error) {
      console.log(error);

      return {
        ...REPOSITORY_RESPONSE,
        success: false,
        error,
      };
    }
  }
  static async getExerciciosVideos() {
    try {
      // const response = await api.get(`/exercicios-videos`)
      const response = await loadCSVasJSON(
        "Banco de Dados - Exercício em Vídeo.csv"
      );

      return {
        ...REPOSITORY_RESPONSE,
        success: true,
        data: response,
      };
    } catch (error) {
      console.log(error);

      return {
        ...REPOSITORY_RESPONSE,
        success: false,
        error,
      };
    }
  }
  static async getParodias() {
    try {
      // const response = await api.get(`/parodias`)
      const response = await loadCSVasJSON("Banco de Dados - Paródias.csv");

      return {
        ...REPOSITORY_RESPONSE,
        success: true,
        data: response,
      };
    } catch (error) {
      console.log(error);

      return {
        ...REPOSITORY_RESPONSE,
        success: false,
        error,
      };
    }
  }
  static async getSimulados() {
    try {
      // const response = await api.get(`/simulados`)
      const response = await loadCSVasJSON("Banco de Dados - Simulados.csv");

      return {
        ...REPOSITORY_RESPONSE,
        success: true,
        data: response,
      };
    } catch (error) {
      console.log(error);

      return {
        ...REPOSITORY_RESPONSE,
        success: false,
        error,
      };
    }
  }
  static async getNaReals() {
    try {
      // const response = await api.get(`/na-reals`)
      const response = await loadCSVasJSON("Banco de Dados - Na Real.csv");

      return {
        ...REPOSITORY_RESPONSE,
        success: true,
        data: response,
      };
    } catch (error) {
      console.log(error);

      return {
        ...REPOSITORY_RESPONSE,
        success: false,
        error,
      };
    }
  }
  static async getPilulas() {
    try {
      // const response = await api.get(`/pilulas`)
      const response = await loadCSVasJSON("Banco de Dados - Pílulas.csv");

      return {
        ...REPOSITORY_RESPONSE,
        success: true,
        data: response,
      };
    } catch (error) {
      console.log(error);

      return {
        ...REPOSITORY_RESPONSE,
        success: false,
        error,
      };
    }
  }
}
