import { useEffect, useState } from "react";
import check from "../../../assets/images/check.png";
import studying from "../../../assets/images/studying.png";

export default function TargetAudience() {
  return (
    // <CurveWrapper
    //   id="target_audience_main"
    //   className="relative bg-white sm:bg-gradient-to-r sm:from-blue-600 sm:to-green-600"
    // >
    <div className="bg-blue-900">
      <div className="container w-screen mx-auto ">
        <div className="flex flex-wrap w-full">
          <div className="flex flex-col w-full text-center  my-8 py-8 px-4 sm:p-0">
            <h2 className="uppercase text-3xl text-white  font-bold">
              Feito para quem busca...
            </h2>
            <div className="flex flex-col sm:flex-row sm:flex-wrap justify-center items-center text-left lg:text-center lg:space-x-14">
              <div className="flex flex-1 px-4">
                <img src={studying} width="480px" />
              </div>
              <div className="flex flex-1 flex-col py-12 space-y-10 mx-auto">
                <DescriptionRow text="Estar preparado para o ENEM" />
                <DescriptionRow text="Vestibulares Regionais e Concursos Públicos" />
                {/* <DescriptionRow text="Ter acesso a uma Plataforma Show de Bola" /> */}
                <DescriptionRow text="Aulas e Conteúdos Completos" />
                <DescriptionRow text="Focar em Exercícios e Atividades" />
                <DescriptionRow text="Poder contar com o apoio de um Professor Especialista" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    // </CurveWrapper>
  );
}

function DescriptionRow({ text }) {
  return (
    <div className="flex items-center space-x-4">
      <img src={check} width="32px" />
      <h3 className="text-2xl font-semibold text-left text-white"> {text} </h3>
    </div>
  );
}

function CurveWrapper({ children, ...props }) {
  const [width, setWidth] = useState(1800);
  useEffect(() => setWidth(window.innerWidth), []);
  return (
    <div {...props}>
      {width > 1080 ? (
        <div>
          <SecondCurve className="hidden sm:block md:absolute inset-0  " />
          <FirstCurve className="hidden sm:block md:absolute inset-0 " />
        </div>
      ) : (
        <div />
      )}
      <div className="relative z-10">{children}</div>
    </div>
  );
}

function FirstCurve(props) {
  return (
    <div {...props}>
      <svg
        style={{ width: "100%" }}
        xmlns="http://www.w3.org/2000/svg"
        viewBox={`0 0 1440 700`}
      >
        <path
          fill="#fefefe"
          fillOpacity="1"
          d="M 0 700 L 0 130 Q 80 10 260 10 C 350 10 400 20 490 70 C 590 140 880 110 930 80 Q 1100 0 1300 30 Q 1370 40 1440 70 L 1440 700 Z"
        ></path>
      </svg>
    </div>
  );
}

function SecondCurve(props) {
  return (
    <div {...props}>
      <svg
        style={{ width: "100%" }}
        xmlns="http://www.w3.org/2000/svg"
        viewBox={`0 0 1440 700`}
      >
        <path
          fill="#d3d3d3"
          fillOpacity="1"
          d="M 0 700 L 0 110 Q 100 30 220 20 Q 420 20 620 120 Q 740 170 910 90 Q 1100 0 1300 20 Q 1380 30 1440 50 L 1440 700 Z"
        ></path>
      </svg>
    </div>
  );
}
