import { useContext, useEffect } from "react";
import { FaPills } from "react-icons/fa";
import {
  FiBook,
  FiBookOpen,
  FiCalendar,
  FiEdit,
  FiEdit2,
  FiEdit3,
  FiLogOut,
  FiMonitor,
  FiMusic,
  FiX,
} from "react-icons/fi";
import { useMediaQuery } from "react-responsive";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import { DrawerContext } from "../../../context/DrawerContext";
import logo from "../../../assets/images/logoSemFundo.png";

export default function Navbar(props) {
  const history = useHistory();
  const { open, setOpen } = useContext(DrawerContext);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  const logOut = () => {
    localStorage.clear();
    history.push("/");
  };

  return (
    <div
      className="fixed lg:static w-screen lg:w-1/5 flex flex-col z-30  bg-gray-900 overflow-y-auto h-screen"
      style={{ transition: ".6s all", left: open ? "-100vw" : "0vw" }}
    >
      {isTabletOrMobile && (
        <div
          onClick={() => setOpen(!open)}
          className="absolute top-8 right-8 text-white text-xl"
        >
          <FiX />
        </div>
      )}

      <div className="flex  justify-center items-center mt-8">
        <div className="flex items-center">
          {/* <svg className="h-12 w-12" viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M364.61 390.213C304.625 450.196 207.37 450.196 147.386 390.213C117.394 360.22 102.398 320.911 102.398 281.6C102.398 242.291 117.394 202.981 147.386 172.989C147.386 230.4 153.6 281.6 230.4 307.2C230.4 256 256 102.4 294.4 76.7999C320 128 334.618 142.997 364.608 172.989C394.601 202.981 409.597 242.291 409.597 281.6C409.597 320.911 394.601 360.22 364.61 390.213Z" fill="#4C51BF" stroke="#4C51BF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                        <path d="M201.694 387.105C231.686 417.098 280.312 417.098 310.305 387.105C325.301 372.109 332.8 352.456 332.8 332.8C332.8 313.144 325.301 293.491 310.305 278.495C295.309 263.498 288 256 275.2 230.4C256 243.2 243.201 320 243.201 345.6C201.694 345.6 179.2 332.8 179.2 332.8C179.2 352.456 186.698 372.109 201.694 387.105Z" fill="white"></path>
                    </svg> */}
          <img src={logo} style={{ width: 40 }} alt="Logo" />
          <span className="text-white text-2xl mx-2 font-semibold">
            Geografia Irada
          </span>
        </div>
      </div>

      <nav className="mt-10 flex-1">
        <NavItem {...props} to="/aulas" title="Aulas" icon={<FiBookOpen />} />
        <NavItem
          {...props}
          to={`/planodeestudos`}
          title="Plano de Estudos"
          icon={<FiCalendar />}
        />
        <NavItem
          {...props}
          to={`/apostilas`}
          title="Materiais"
          icon={<FiBook />}
        />
        <NavItem
          {...props}
          to={`/pilulas`}
          title="Pílulas Iradas"
          icon={<FaPills />}
        />
        <NavItem {...props} to={`/naReal`} title="Na Real" icon={<FiEdit3 />} />
        <NavItem
          {...props}
          to={`/simulados`}
          title="Simulados"
          icon={<FiEdit />}
        />
        <NavItem
          {...props}
          to={`/exerciciosExtras`}
          title="Exercícios Extras"
          icon={<FiEdit2 />}
        />
        <NavItem
          {...props}
          to={`/exerciciosVideos`}
          title="Exercícios em Vídeos"
          icon={<FiMonitor />}
        />
        <NavItem
          {...props}
          to={`/parodias`}
          title="Paródias"
          icon={<FiMusic />}
        />
      </nav>

      <button
        onClick={logOut}
        className="mb-5 mt-4 transition mx-5 flex items-center justify-center font-semibold py-2 text-sm md:text-base cursor-pointer border-b-4 border-blue-900 transform hover:translate-y-1 hover:border-opacity-0  bg-blue-800 hover:bg-blue-600 rounded-full text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        <span className="mr-2">Sair</span>
        <FiLogOut />
      </button>
    </div>
  );
}

function NavItem({ to, icon, title, location }) {
  const match = useRouteMatch();
  const history = useHistory();
  const { open, setOpen } = useContext(DrawerContext);
  let className =
    "flex items-center cursor-pointer mt-2 py-3 px-6  text-gray-100";
  if (location.pathname == `${match.path}${to}`)
    className += " bg-gray-700 bg-opacity-25 font-bold text-green-500";

  function goTo() {
    history.push(`${match.path}${to}`);
    setOpen(!open);
  }

  return (
    <a onClick={goTo} className={className}>
      <div> {icon} </div>
      <span className="mx-3"> {title} </span>
    </a>
  );
}
