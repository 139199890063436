import React, { useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import Alert from "../../components/Alert";
import Loader from "../../components/Loader";
import unApi from "../../services/unauthenticatedApi";
import logo from "../../assets/images/logoSemFundo.png";
import { useAuth } from "../../context/AuthProvider";

export default function RedefinePassword() {
  const { confirmPassReset } = useAuth();
  const [newPassword, setnewpassword] = useState("");
  const [confirmpassword, setconfirmpassword] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [showloader, setshowloader] = useState(false);
  const history = useHistory();
  const query = useQuery();

  function showLoader() {
    setshowloader(true);
  }
  function hideLoader() {
    setshowloader(false);
  }

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  async function sendNewPass(e) {
    e.preventDefault();
    setError(null);
    showLoader();
    if (newPassword != confirmpassword) {
      hideLoader();
      return setError("As senhas precisam ser iguais");
    }
    if (newPassword == "" || confirmpassword == "") {
      hideLoader();
      return setError("Preencha os Dois Campos");
    }

    if (!query.get("oobCode")) {
      hideLoader();
      return setError(
        "Parece que este não e o link que você recebeu em seu email :("
      );
    }

    try {
      //   const response = await unApi.post(
      //     "/auth/reset-password",
      //     {
      //       code: query.get("code"),
      //       password: newPassword,
      //       passwordConfirmation: confirmpassword,
      //     },
      //     {
      //       validateStatus: (status) => {
      //         return true;
      //       },
      //     }
      //   );

      await confirmPassReset(query.get("oobCode"), newPassword);

      hideLoader();
      setSuccess(
        "Parabéns, você alterou sua senha com sucesso! Volte para o Login e entre na plataform com sua senha nova"
      );
      return;
    } catch (error) {
      hideLoader();
      console.log(error);
      return setError("Ocorreu um erro ao alterar a senha. ");
    }
  }

  return (
    <div className="min-h-screen flex justify-center bg-gradient-to-r from-blue-800 to-green-500 ">
      <div className="max-w-md w-full">
        <div className="flex flex-col items-center justify-center bg-white h-screen p-8 lg:shadow-md">
          <div>
            <img className="mx-auto h-24 w-auto" src={logo} alt="Workflow" />
            <h2 className="mt-6 text-center text-3xl font-bold text-gray-900">
              Informar Senha Nova
            </h2>
            <p className="mt-2 text-center text-sm text-gray-500">
              Informe uma nova senha para poder acessar a plataforma
            </p>
          </div>

          <Alert error={error} success={success} />

          <form className="mt-8 space-y-6 w-full">
            <div className="rounded-md shadow-sm -space-y-px">
              <div>
                <label for="password" className="sr-only">
                  Senha
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  onChange={(e) => {
                    setnewpassword(e.target.value);
                  }}
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Nova Senha"
                />
              </div>
              <div>
                <label for="confirm-password" className="sr-only">
                  Confirmar Senha
                </label>
                <input
                  id="confirm-password"
                  name="confirm-password"
                  type="password"
                  onChange={(e) => {
                    setconfirmpassword(e.target.value);
                  }}
                  autocomplete="current-password"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Repita sua Nova Senha"
                />
              </div>
            </div>

            <div className="flex items-center justify-between">
              <div className="text-sm">
                <Link
                  to="/login"
                  className="font-medium text-blue-600 hover:text-indigo-500"
                >
                  Ir para o login
                </Link>
              </div>
            </div>

            <div>
              <button
                onClick={sendNewPass}
                className="w-full transition px-2 md:px-7 py-2 text-sm md:text-base bg-gradient-to-r from-blue-600 md:from-blue-700 to-green-500 rounded-md text-white font-bold cursor-pointer border-b-4 border-blue-900 transform hover:translate-y-1 hover:border-opacity-0 hover:from-green-400 hover:to-green-400 sm:hover:from-blue-600 sm:hover:to-green-300 text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                {showloader ? <Loader /> : <span>Alterar Senha</span>}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
