import React, { useContext, useState } from "react";
import { FiArrowLeft, FiMenu } from "react-icons/fi";
import { DrawerContext } from "../../context/DrawerContext";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import PlatformRepository from "../../repositories/platform_repository";

export default function Header({
  title,
  showDropdown,
  onDropdownChange,
  showBackButton,
}) {
  const { open, setOpen } = useContext(DrawerContext);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  // const local = JSON.parse(localStorage.getItem('@userData'));
  const [classes, setClasses] = useState([]);
  const history = useHistory();
  const [selectedClass, setClass] = useState(0);

  function dropdownChanged(e) {
    setClass(e.target.value);
    onDropdownChange(e.target.value);
  }

  async function getClasses() {
    const response = await PlatformRepository.getClasses(true);
    if (response.success) {
      setClasses(response.data);
      setClass(classes.length > 0 ? classes[0].id : {});
    }
  }

  useEffect(() => {
    getClasses();
  }, []);

  return (
    <header className="flex flex-row items-center w-full bg-gray-100 px-4 py-4 lg:px-16 lg:py-5 shadow-md">
      <div className="flex items-center text-gray-800 font-bold text-2xl lg:text-4xl flex-1">
        {isTabletOrMobile && !showBackButton && (
          <FiMenu
            className="mr-4"
            onClick={() => {
              setOpen(!open);
            }}
          />
        )}
        {showBackButton && (
          <FiArrowLeft
            onClick={() => history.goBack()}
            className="mr-4"
          ></FiArrowLeft>
        )}
        <h2>{title}</h2>
      </div>
      {false ? (
        <div>
          <h4 className="text-gray-600 font-semibold">Turma:</h4>
          <select
            className="rounded-md border-2 border-gray-400 py-2 px-4 pr-12 shadow-md focus:ring-4 focus:ring-gray-300 focus:outline-none"
            name="turmas"
            id="turmas"
            value={selectedClass}
            onChange={dropdownChanged}
          >
            {classes.map((turm) => (
              <option key={turm.id} value={turm.id}>
                {turm.nome}
              </option>
            ))}
          </select>
        </div>
      ) : (
        <div />
      )}
    </header>
  );
}
