// import logoImg from '../../assets/logoGeografiaIrada.svg'
// import fotoTeacher from '../../assets/fotoTeacher2.png'
import aulasIcon from "../../../assets/icons/elearning.svg";
import exercicioIcon from "../../../assets/icons/school.svg";
import simuladoIcon from "../../../assets/icons/homework.svg";
import apostilaIcon from "../../../assets/icons/notebook.svg";
import maisIcon from "../../../assets/icons/teacher.svg";
import musicIcon from "../../../assets/icons/music.svg";
// import dep1 from '../../../assets/depoimentos/1.png'
// import dep2 from '../../../assets/depoimentos/2.png'
// import dep4 from '../../../assets/depoimentos/4.png'
import enemIcon from "../../../assets/icons/test.svg";
import vestibulandoIcon from "../../../assets/icons/studying.svg";
import concurseiroIcon from "../../../assets/icons/asking.svg";
import studentIcon from "../../../assets/icons/student.svg";
import calendarIcon from "../../../assets/icons/calendar.svg";
import waveIcon from "../../../assets/icons/wave.svg";
import fundamentalClassIcon from "../../../assets/icons/study.svg";
import extensivoicon from "../../../assets/icons/calendar (1).svg";
import intensivoicon from "../../../assets/icons/fast.svg";
import fundamentalIcon from "../../../assets/icons/book.svg";

import studying from "../../../assets/images/studying.png";
import enem from "../../../assets/images/icons8-termos-e-condições-64.png";
import chart from "../../../assets/images/icons8-pesquisa-64.png";
import check from "../../../assets/images/check.png";

export default function PlatformContents() {
  return (
    <div className="relative bg-gradient-to-r from-blue-600 to-green-600">
      {/* <Curve /> */}
      <div className="container mx-auto w-screen py-16">
        <div className="flex flex-wrap w-full">
          <div className="flex flex-col text-center w-full">
            <h2 className="text-3xl text-white uppercase font-bold mb-8">
              Assinando você terá acesso à...
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
              <Card
                title="Aulas"
                description="Para aprender, entender e executar"
                img={aulasIcon}
              />
              <Card
                title="Exercícios"
                description="Para fixar tudo o que já aprendeu"
                img={exercicioIcon}
              />
              <Card
                title="Apostilas"
                description="Que te acompanham no aprendizado"
                img={apostilaIcon}
              />
              <Card
                title="Simulados"
                description="Te preparando para os vestibulares"
                img={simuladoIcon}
              />
              <Card
                title="Plano de Estudos"
                description="Para te ajudar a se organizar nos conteúdos"
                img={calendarIcon}
              />
              <Card
                title="Paródias"
                description="Ajudando a fizar de forma divertida"
                img={musicIcon}
              />
              <Card
                title="Pílulas"
                description="Para te praparar para as principais questões"
                img={maisIcon}
              />
              <Card
                title="Na Real"
                description="Aprenda sobre os principais fenômenos de nosso cotidiano"
                img={waveIcon}
              />
            </div>
          </div>
        </div>
      </div>
      <SecondCurve />
    </div>
  );
}

function Card({ title, img, description }) {
  return (
    <div className="px-4 md:p-0">
      <div className="flex flex-col justify-center items-center p-4 shadow-lg rounded-md bg-white ">
        <h3 className="text-gray-600 text-2xl uppercase font-bold ">
          {" "}
          {title}{" "}
        </h3>
        <p className="mb-4 text-gray-500"> {description} </p>
        <img src={img} width="120px" />
      </div>
    </div>
  );
}

function Curve(props) {
  return (
    <div {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 200">
        <path
          fill="#fefefe"
          fillOpacity="1"
          d="M0,192L60,165.3C120,139,240,85,360,85.3C480,85,600,139,720,138.7C840,139,960,85,1080,69.3C1200,53,1320,75,1380,85.3L1440,96L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z"
        ></path>
      </svg>
    </div>
  );
}

function SecondCurve(props) {
  return (
    <div {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 200">
        <path
          fill="#fefefe"
          fillOpacity="1"
          d="M0,128L60,106.7C120,85,240,43,360,48C480,53,600,107,720,117.3C840,128,960,96,1080,74.7C1200,53,1320,43,1380,37.3L1440,32L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"
        ></path>
      </svg>
    </div>
  );
}
