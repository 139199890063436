import React, { useEffect, useState } from "react";
import { FiArrowRight, FiDownload, FiFileText, FiFilm } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import Header from "../../../../components/Header";
import PlatformRepository from "../../../../repositories/platform_repository";
import { getUserData } from "../../../../services/auth";

export default function Aulas() {
  const [contents, setContents] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setError] = useState(null);

  // const local = getUserData()
  // const classes = local.plan.classes;

  async function getContentsAndSubjects(turma) {
    setLoading(true);
    const response = await PlatformRepository.getContents(turma, true);
    setLoading(false);
    if (response.success) {
      // console.log(response.data)
      setContents(response.data.conteudos);
      setSubjects(response.data.materias);
    } else {
      setError(response.error);
    }
  }

  useEffect(() => {
    getContentsAndSubjects(0);
  }, []);

  return (
    <div className="aulaMain">
      <Header
        title={"Aulas e Conteúdos"}
        showDropdown={true}
        onDropdownChange={getContentsAndSubjects}
      />

      <section className="p-4 lg:p-8">
        {!loading ? (
          errorMessage == null ? (
            contents.length == 0 ? (
              <label>Nenhum conteúdo até o momento</label>
            ) : (
              <ContentsSection contents={contents} subjects={subjects} />
            )
          ) : (
            <label>{errorMessage}</label>
          )
        ) : (
          <label>Carregando...</label>
        )}
      </section>
    </div>
  );
}

function ContentsSection({ contents, subjects }) {
  const history = useHistory();

  function navigateToContent(content) {
    history.push("conteudo", { content });
  }
  console.log(contents.length);
  return (
    <div className="bg-gray-100 p-8 ">
      {subjects
        .filter((subject) => contents.some((c) => c.materia === subject))
        .map((subject, index) => {
          return (
            <div className="mb-8" key={subject.id}>
              <h1 className="text-gray-700 text-3xl font-bold mb-4">
                {subject.nome}
              </h1>
              <div className="grid grid-cols-1 lg:grid-cols-4 gap-8 ">
                {contents.map((content) => {
                  if (subject != content.materia) return;
                  return (
                    <a
                      className="transition duration-500 transform hover:-translate-y-1 flex flex-col shadow-md rounded-md bg-transparent text-green-800 border-2 border-green-700 font-bold text-white hover:bg-green-800 hover:text-white hover:border-opacity-0 p-4 cursor-pointer"
                      onClick={() => navigateToContent(content)}
                      key={content.id}
                    >
                      <p className="text-2xl mb-4">{content.nome}</p>
                      <div className="flex flex-row text-xl">
                        <div className="flex-1 flex">
                          <FiFilm />
                          <FiDownload />
                          <FiFileText />
                        </div>
                        <div>
                          <FiArrowRight />
                        </div>
                      </div>
                    </a>
                  );
                })}
              </div>
              <div className="border-b-2 border-gray-800 mt-8"></div>
            </div>
          );
        })}
    </div>
  );
}
