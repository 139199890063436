import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { FiDownload, FiFileText, FiEdit2, FiArrowLeft } from 'react-icons/fi';
import Youtube from 'react-youtube'
import Header from '../../../../components/Header';
import { useMediaQuery } from 'react-responsive';

export default function NaRealPage(props) {

    const history = useHistory();
    const nareal = props.location.state;
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })

    return (
        <div className="conteudoPage">
            <Header title="Geografia Na Real" showBackButton />


            <section>
                <div className="p-4 lg:p-8">
                    <div className="flex-1 mb-4 lg:mr-4 flex justify-center" style={{ backgroundColor: "#000" }} >
                        <Youtube videoId={nareal.linkVideo.replace("https://www.youtube.com/watch?v=", "")} className={isTabletOrMobile ? "w-full" : ""} ></Youtube>
                    </div>
                    <div className="flex flex-col bg-gray-100 rounded-md shadow-sm p-8 ">
                        <h2 className="text-3xl text-gray-900 font-bold" >{nareal.nome}</h2>
                        <p>Prof. Marcelo</p>
                    </div>

                </div>


            </section>
        </div>
    )
}