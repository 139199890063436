import React from "react";
import { FiDownload } from "react-icons/fi";
import { useMediaQuery } from "react-responsive";
import Youtube from "react-youtube";
import Header from "../../../../components/Header";

export default function ExercicioVideoPage(props) {
  const exercicio = props.location.state;
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  return (
    <div className="conteudoPage">
      <Header title="Exercicio em Vídeo" showBackButton />

      <section>
        <div className="p-4 lg:p-8">
          <div
            className="flex-1 mb-4 lg:mr-4 flex justify-center"
            style={{ backgroundColor: "#000" }}
          >
            <Youtube
              videoId={exercicio.linkVideo.replace(
                "https://www.youtube.com/watch?v=",
                ""
              )}
              className={isTabletOrMobile ? "w-full" : ""}
            ></Youtube>
          </div>
          <div className="flex flex-col bg-gray-100 rounded-md shadow-sm p-8 ">
            <h2 className="text-3xl text-gray-900 font-bold">
              {exercicio.nome}
            </h2>
            <p className="mb-4">Prof. Marcelo</p>
            <div className="flex justify-center">
              <a
                className="transition mx-5 w-3/6 flex items-center justify-center font-semibold py-2 text-sm md:text-base cursor-pointer border-b-4 border-blue-900 transform hover:translate-y-1 hover:border-opacity-0  bg-blue-800 hover:bg-blue-600 rounded-full text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                target="_blank"
                href={exercicio.linkDownload}
              >
                {" "}
                <FiDownload /> Download Exercício{" "}
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
