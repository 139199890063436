import React, { useState, useEffect } from "react";
import { FiDownload, FiMenu } from "react-icons/fi";
import Header from "../../../../components/Header";
import platform_repository from "../../../../repositories/platform_repository";
import { BASE_URL } from "../../../../utils/constants";

export default function Apostila() {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setError] = useState(null);
  const [apostilas, setApostilas] = useState([]);

  useEffect(() => {
    getApostilas();
  }, []);

  async function getApostilas() {
    setLoading(true);
    const response = await platform_repository.getApostilas();
    setLoading(false);
    if (response.success) {
      setApostilas(response.data);
    } else {
      setError(response.error);
    }
  }

  return (
    <div className="apostilaMain">
      <Header showDropdown={false} title="Apostilas" />

      <section className="bg-gray-100 p-8 m-4 lg:m-8 rounded-sm shadow-md">
        {!loading ? (
          errorMessage == null ? (
            apostilas.length == 0 ? (
              <label>Nenhuma apostila cadastrada por enquanto...</label>
            ) : (
              <ApostilasSection apostilas={apostilas} />
            )
          ) : (
            <label>{errorMessage}</label>
          )
        ) : (
          <label>Carregando...</label>
        )}
      </section>
    </div>
  );
}

function ApostilasSection({ apostilas }) {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-4 gap-8 ">
      {apostilas.map((apostila) => (
        <a
          className="transition duration-500 transform hover:-translate-y-1 flex flex-col shadow-md rounded-md bg-blue-900 text-white border-2 border-blue-900 font-bold text-white hover:text-green-500 hover:border-opacity-0 p-4 cursor-pointer "
          href={apostila.urlDownload ? apostila.urlDownload : BASE_URL}
          target="_blank"
        >
          <h2 className="text-xl mb-4">{apostila.nome}</h2>

          <div className="flex ">
            <FiDownload className="mr-2"></FiDownload>
            <p>Download</p>
          </div>
        </a>
      ))}
    </div>
  );
}
