const URL_CSVS = `${process.env.PUBLIC_URL}/data`

export default async function loadCSVasJSON(csvName){
    const response = await fetch(`${URL_CSVS}/${csvName}`, {
        headers: {
            'Content-Type': 'text/csv',
            'Accept': 'text/csv'
        }
    })
    const responseCsv = await response.text()

    
    // https://www.bennadel.com/blog/1504-ask-ben-parsing-csv-strings-with-javascript-exec-regular-expression-command.htm
    //
    const strDelimiter = ",";
    var objPattern = new RegExp(
        (
            // Delimiters.
            "(\\" + strDelimiter + "|\\r?\\n|\\r|^)" +

            // Quoted fields.
            "(?:\"([^\"]*(?:\"\"[^\"]*)*)\"|" +

            // Standard fields.
            "([^\"\\" + strDelimiter + "\\r\\n]*))"
        ),
        "gi"
        );

    var arrData = [[]];
    var arrMatches = null;

    while (arrMatches = objPattern.exec( responseCsv )){
        var strMatchedDelimiter = arrMatches[ 1 ];
        if (
            strMatchedDelimiter.length &&
            strMatchedDelimiter !== strDelimiter
            ){
            arrData.push( [] );
        }

        var strMatchedValue;

        if (arrMatches[ 2 ]){
            strMatchedValue = arrMatches[ 2 ].replace(
                new RegExp( "\"\"", "g" ),
                "\""
                );

        } else {
            strMatchedValue = arrMatches[ 3 ];
        }

        arrData[ arrData.length - 1 ].push( strMatchedValue );
    }
    //

    const registros = []
    arrData.forEach((element, i) => {
        if (i == 0)
            return
        const json = {
            "id": i-1
        }
        element.forEach((value, i) => {
            json[arrData[0][i]] = value
        })
        registros.push(json)
    });
    return registros
}

// export const loadCSVasJSON = (csvName, callback) => {
    
//     fetch('data/' + csvName, {
//         headers: {
//             'Content-Type': 'text/csv',
//             'Accept': 'text/csv'
//         }
//     }).then(function (response) {
//         csvarray = $.csv.toArrays(response)
//         registros = []
//         csvarray.forEach((element, i) => {
//             if (i == 0)
//                 return
//             json = {}
//             element.forEach((value, i) => {
//                 json[csvarray[0][i]] = value
//             })
//             registros.push(json)
//         });
//         return registros
//     }).then(function (response) {
//         callback(response)
//     })
// }