import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import logo from "../../assets/images/logoSemFundo.png";
import Alert from "../../components/Alert";
import Loader from "../../components/Loader";
import { useAuth } from "../../context/AuthProvider";

export default function LoginPage() {
  const { signin } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [showloader, setshowloader] = useState(false);
  const history = useHistory();

  function showLoader() {
    setshowloader(true);
  }
  function hideLoader() {
    setshowloader(false);
  }

  async function login(e) {
    setError(null);
    e.preventDefault();
    if (email == "" || password == "") return setError("Todos os Campos são Necessários");
    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) return setError("Email não é Válido");

    showLoader();
    try {
      await signin(email, password);
      history.push(`/plataforma/aulas`);
    } catch (error) {
      console.log(error);
      switch (error?.code) {
        case "auth/user-not-found":
          setError("Usuário não encontrado");
          break;
        case "auth/wrong-password":
          setError("Senha incorreta");
          break;
        default:
          setError("Ocorreu um erro ao se logar");
          break;
      }
    }
    hideLoader();
  }

  return (
    <div className="min-h-screen flex justify-center bg-gradient-to-r from-blue-800 to-green-500 ">
      <div className="max-w-lg w-full">
        <div className="flex flex-col items-center justify-center bg-white h-screen p-8 lg:shadow-md">
          <div>
            <img className="mx-auto h-24 w-auto" src={logo} alt="gepgrafia Irada" />
            <h2 className="mt-6 text-center text-3xl font-bold text-gray-900">Realize o seu Login</h2>
            <p className="mt-2 text-center text-sm text-gray-500">Preencha o formulário para entrar na plataforma</p>
          </div>

          <Alert error={error} />

          <form className="mt-8 space-y-6 w-full">
            <div className="rounded-md shadow-sm -space-y-px">
              <div>
                <label for="email-address" className="sr-only">
                  Email address
                </label>
                <input
                  id="email-address"
                  name="email"
                  type="email"
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  autocomplete="email"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Email"
                />
              </div>
              <div>
                <label for="password" className="sr-only">
                  Password
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  autocomplete="current-password"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Senha"
                />
              </div>
            </div>

            <div className="flex items-center justify-between">
              <div className="text-sm">
                <Link to="/recuperar-senha" className="font-medium text-blue-600 hover:text-indigo-500">
                  Esqueceu sua senha?
                </Link>
              </div>
            </div>

            <div>
              <button
                onClick={login}
                className="w-full transition px-2 md:px-7 py-2 text-sm md:text-base bg-gradient-to-r from-blue-600 md:from-blue-700 to-green-500 rounded-md text-white font-bold cursor-pointer border-b-4 border-blue-900 transform hover:translate-y-1 hover:border-opacity-0 hover:from-green-400 hover:to-green-400 sm:hover:from-blue-600 sm:hover:to-green-300 text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                {showloader ? <Loader /> : <span>Entrar</span>}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
