import React, { useState } from "react";
import unApi from "../../services/unauthenticatedApi";
import { Link } from "react-router-dom";
import Alert from "../../components/Alert";
import Loader from "../../components/Loader";
import logo from "../../assets/images/logoSemFundo.png";
import { useAuth } from "../../context/AuthProvider";

export default function ForgetPasswordPage() {
  const { sendResetPasswordEmail } = useAuth();
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [showloader, setshowloader] = useState(false);

  function showLoader() {
    setshowloader(true);
  }
  function hideLoader() {
    setshowloader(false);
  }

  async function sendEmail(e) {
    showLoader();
    e.preventDefault();
    setSuccess(false);
    setError(false);
    // const error = document.getElementsByClassName('error');
    try {
      // const response = await unApi.post('/auth/forgot-password', { email: email })
      await sendResetPasswordEmail(email);
      hideLoader();
      setSuccess(
        "Você receberá um email para recuperar sua senha em breve. Não esqueça de verificar as pastas de Lixo ou Spam, nossa mensagem pode ter caido lá ;)"
      );
    } catch (error) {
      hideLoader();
      return setError("Ocorreu algum erro, tente novamente mais tarde");
    }
  }

  return (
    <div className="min-h-screen flex justify-center bg-gradient-to-r from-blue-800 to-green-500 ">
      <div className="max-w-md w-full">
        <div className="flex flex-col items-center justify-center bg-white h-screen p-8 lg:shadow-md">
          <div>
            <img className="mx-auto h-24 w-auto" src={logo} alt="Workflow" />
            <h2 className="mt-6 text-center text-3xl font-bold text-gray-900">
              Insira seu email
            </h2>
            <p className="mt-2 text-center text-sm text-gray-500">
              Preencha o campo do Email para receber uma mensagem com um link
              para gerar uma nova senha.
            </p>
          </div>

          <Alert error={error} success={success} />

          <form className="mt-8 space-y-6 w-full">
            <div className="rounded-md shadow-sm">
              <div>
                <label for="email-address" className="sr-only">
                  Email address
                </label>
                <input
                  id="email-address"
                  name="email"
                  type="email"
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  autocomplete="email"
                  required
                  className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Email"
                />
              </div>
            </div>

            <div className="flex items-center justify-between">
              <div className="text-sm">
                <Link
                  to="/login"
                  className="font-medium text-blue-600 hover:text-indigo-500"
                >
                  Voltar para o login
                </Link>
              </div>
            </div>

            <div>
              <button
                onClick={sendEmail}
                className="w-full transition px-2 md:px-7 py-2 text-sm md:text-base bg-gradient-to-r from-blue-600 md:from-blue-700 to-green-500 rounded-md text-white font-bold cursor-pointer border-b-4 border-blue-900 transform hover:translate-y-1 hover:border-opacity-0 hover:from-green-400 hover:to-green-400 sm:hover:from-blue-600 sm:hover:to-green-300 text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                {showloader ? <Loader /> : <span>Enviar Email</span>}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
