import MainLanding from "./components/MainLanding";
import PlatformContents from "./components/PlatformContents";
import Pricing from "./components/Pricing";
import TargetAudience from "./components/TargetAudience";
import { BsArrowUp } from "react-icons/bs";
import { useEffect, useState } from "react";
import ElementaryPricing from "./components/ElementaryPricing";
import Classes from "./components/Classes";
import About from "./components/About";
import Depositions from "./components/Depositions";
import Footer from "./components/Footer";
import Descolados from "./components/Descolados";
import bannerTeachersDesktop from "../../assets/images/banner-teachers.jpeg";
import bannerTeachersMobile from "../../assets/images/banner-teachers-mobile.jpeg";
import { useMediaQuery } from "react-responsive";

export default function Front() {
  const isMobile = useMediaQuery({ maxWidth: 680 });

  // function scrollToHowAccess() {
  //   var node = document.getElementById("howAccess");
  //   var viewportOffset = node.getBoundingClientRect();
  //   var top = viewportOffset.top;
  //   window.scrollBy({ top: top - 20, behavior: "smooth" });
  // }
  return (
    <div>
      <MainLanding />
      <TargetAudience />
      <img
        // onClick={scrollToHowAccess}
        src={isMobile ? bannerTeachersMobile : bannerTeachersDesktop}
        alt="Material para Professores"
        className="w-full"
      />
      <PlatformContents />
      {/* <Pricing /> */}
      {/* <ElementaryPricing/> */}
      <Depositions />
      {/* <Classes /> */}
      {/* <Descolados/> */}
      <About />
      <Footer />
      <BackToTopButton />
    </div>
  );
}

function BackToTopButton() {
  const [isTop, setIsTop] = useState(true);

  useEffect(() => {
    var limit = Math.max(
      document.body.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.clientHeight,
      document.documentElement.scrollHeight,
      document.documentElement.offsetHeight
    );

    window.addEventListener("scroll", () => handleScroll(limit));
  }, []);

  function handleScroll(limit) {
    if (window.pageYOffset === 0 || (window.pageYOffset / limit) * 100 > 91) {
      setIsTop(true);
    } else {
      setIsTop(false);
    }
  }

  function handleClick() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  return (
    <div
      className={`z-10 fixed transition transform bottom-12 right-8 cursor-pointer `}
      style={{
        transform: isTop ? "translate(10rem, 0px)" : "translate(0px, 0px)",
      }}
    >
      <button
        onClick={handleClick}
        className="p-4 text-white text-4xl bg-gradient-to-r from-red-500 to-yellow-600 rounded-full shadow-lg focus:outline-none focus:ring ring-yellow-800 ring-offset-2"
      >
        <BsArrowUp />
      </button>
    </div>
  );
}
