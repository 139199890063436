import { collection, doc, getDocs, setDoc } from "firebase/firestore";
import { db } from "../services/firebase";

export const getUserPlans = async (userId) => {
  const query = collection(db, `users/${userId}/userPlans`);
  const docs = await getDocs(query);
  const plans = [];
  for (let userPlanDoc of docs.docs) {
    plans.push({ ...userPlanDoc.data(), type: userPlanDoc.id === "free" ? "free" : "paid" });
  }
  return plans;
};

export const createUserFreePlan = async (userId) => {
  let newUserPlan = {
    createdAt: Date.now(),
    type: "free",
  };
  await setDoc(doc(db, `users/${userId}/userPlans/free`), newUserPlan);
  return newUserPlan;
};
