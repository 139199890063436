

export default function Footer() {
    return (
        <div className=" bg-gray-900 " >
        <div className="container w-screen text-white mx-auto">
            <div className="flex flex-row p-4">
                <div className="flex flex-col " >
                    <h3 className="text-lg font-semibold">Contato</h3>
                    <p>geografiairada@gmail.com</p>
                    <p>(47) 999813532</p>
                </div>
                <div className="flex flex-1 flex-row-reverse items-center">
                    <h3 className="text-xl uppercase font-bold" >
                        Beyonders
                        </h3>
                </div>
            </div >
        </div>
        </div>
    )
}